import React from 'react';
import LazyLoaderWrapper from 'atoms/LazyLoaderWrapper';

const getDynamicHeaderFooter = props => {
  return (
    <LazyLoaderWrapper {...props} testId='root-header-container' importPath='ROOT_HEADER_CONTAINER'></LazyLoaderWrapper>
  );
};

const HeaderFooterSwitch = props => {
  return getDynamicHeaderFooter(props);
};

export default HeaderFooterSwitch;
