export const AUTO_DETECT_CONSTANTS = {
  LOCATION_BLOCK_BANNER: 'LOCATION_BLOCK_BANNER',
  AUTO_DETECTION_BUTTON_FLAG: 'AUTO_DETECTION_BUTTON_FLAG',
  DINE_IN: 'dine-in',
  PICK_UP: 'pickup',
  DELIVERY: 'delivery',
  DISTANCE_500MR: 500,
  DISTANCE_7500MR: 7500,
  DISTANCE_20000MR: 20000,
  GRANTED: 'GRANTED',
  BLOCKED: 'BLOCKED',
  START_ORDER_BUTTON: 'Start order',
  BROWSE_MENU_BUTTON: 'Browse menu',
  PREVIOUS_ADDRESS_BUTTON: 'Use previous address',
  UPDATE_LOCATION_BUTTON: 'Update location',
  SET_LOCATION_BUTTON: 'Set Location',
};

export const MODAL_MSG_CONSTANTS = {
  TAKEAWAY_TITLE: 'takeAwayTitle',
  TAKEAWAY_CONTENT: 'takeAwayContent',
  TAKEAWAY_TOAST_MSG: 'takeAwayToastMsg',
  DINEIN_TITLE: 'dineInTitle',
  DINEIN_CONTENT: 'dineInContent',
  DINEIN_TOAST_MSG: 'dineInToastMsg',
  DELIVERY_TOAST_MSG: 'deliveryToastMsg',
  NOSTORE_TITLE: 'noStoreTitle',
  NOSTORE_CONTENT: 'noStoreContent',
  NOSTORE_TOAST_MSG: 'noStoreToastMsg',
  CLOSED_STORE_TITLE: 'closedStoreTitle',
  CLOSED_STORE_CONTENT: 'closedStoreContent',
  CLOSED_STORE_TOAST_MSG: 'closedStoreToastMsg',
  LOCALIZATION_CHANGE_TITLE1: 'localizationChangeTitle1',
  LOCALIZATION_CHANGE_TITLE2: 'localizationChangeTitle2',
  LOCALIZATION_CHANGE_CONTENT: 'localizationChangeContent',
  PREVIOUS_ADDRESS: 'previousAddress',
  SET_LOCATION_TEXT: 'setLocationText',
  LOCATION_ALERT_MSG: 'locationAlertMessage',
};
