import { createClient } from 'contentful';
import { config } from '@kfc-global/react-shared/config/config.utils';
import {
  CONTENTFUL_SPACE,
  CONTENTFUL_ENVIRONMENT,
  CONTENTFUL_ACCESS_TOKEN,
} from '@kfc-global/react-shared/config/config.constants';

let cmsClient;
let authorized;

export function initCmsClient() {
  //## PROD ##
  const space = config(CONTENTFUL_SPACE);
  const environment = config(CONTENTFUL_ENVIRONMENT);
  const accessToken = config(CONTENTFUL_ACCESS_TOKEN);

  cmsClient = createClient({ space, environment, accessToken, removeUnresolved: true });
  return cmsClient?.getSpace()?.then(space => {
    authorized = true;
    return space;
  });
}

export function getCmsClient() {
  return cmsClient;
}
