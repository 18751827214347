import { DYNAMIC_IMPORT_CONFIG_KEY } from 'common/constants/SharedConstants';

const modulator = async configName => {
  const dynamicImportConfigPath = await import('common/config/dynamicImportConfig');
  return dynamicImportConfigPath?.default?.[process?.env?.REACT_APP_TENANT_CODE?.toUpperCase()]?.[configName];
};

const globalModules = {
  homePageConstantData: {
    name: DYNAMIC_IMPORT_CONFIG_KEY?.HOME_PAGE_CONSTANTS,
    modulesData: null,
  },
  globalStyleConstantData: {
    name: DYNAMIC_IMPORT_CONFIG_KEY?.GLOBAL_STYLE_SCRIPTS,
    modulesData: null,
  },
};

export const initModulator = () => {
  Object?.keys?.(globalModules)?.forEach(async key => {
    const data = await modulator(globalModules?.[key]?.name);
    globalModules[key].modulesData = data?.default;
  });
};

export default globalModules;
