import React from 'react';
import ModalCompItems from '../Presentation/ModalCompItems';

/**
 * Modal Popup Component
 */
export const ModalComponent = props => {
  return <ModalCompItems onExitedCallback={props.onModalExitedCallback} {...props} />;
};

// Default Export
export default ModalComponent;
