import React, { useRef } from 'react';
import ImageCompItems from '../Presentation/ImageCompItems';

/**
 * Image Component Container
 */
const ImageItem = (props, containerRef) => <ImageCompItems {...props} containerRef={containerRef} />;

const ImageComponent = props => {
  const imgRef = useRef(null);

  return props?.isContentfulImage ? (
    <span ref={imgRef}>{ImageItem(props, imgRef.current?.parentElement)}</span>
  ) : (
    ImageItem(props)
  );
};

// default export
export default ImageComponent;
