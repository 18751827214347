export const VIEW_RECEIPT = 'viewReceipt';
export const REORDER = 'reorderBtn';
export const MY_ACCOUNT = 'myAccount';
export const ORDER_IT_AGAIN = 'orderItAgain';
export const SEE_FULL_ORDER_HISTORY = 'seeFullOrderHistory';
export const CONTINUE = 'continue';
export const CANCEL = 'cancel';
export const ACCOUNT_FAVORDERS = 'Account - Occasions';
export const HOMEPAGE_REORDER = 'HomePage - Reorder';

export const ReorderConstants = {
  LARGE_CARRY_BAG: 'largeCarryBag',
  ADD_HOPE_TEXT: 'addhope',
  SEARCH_IN_CART: 'SEARCH_IN_CART',
  SET_DISPOSITION: 'SET_DISPOSITION',
  CLOSED: 'CLOSED',
  DELIVER_TO: 'deliveryTo',
  WHEN: 'when',
  PLEASE_REVIEW_YOUR_ORDER: 'pleaseReviewYourOrder',
  QUICK_ORDER: 'quickOrder',
  TODAY: 'today',
  ASAP: 'asap',
  ITEMS: 'items',
  WANT_TO_MODIFY: 'wantToModify',
  ITEM_ADDED_TO_CART_MSG: 'itemAddedToCartMsg',
  KEEP_SHOPING: 'keepShoping',
  ADD_A_COUPON: 'addACoupon',
  SUBTOTAL: 'subTotal',
  GST: 'GST',
  REST_HANDLING: 'cartIncluTax',
  DISCOUNT: 'DiscountText',
  TOTAL: 'total',
  CONTINUE_TO_PAYMEN: 'continuePayment',
  CLEAR_CART_MSG: 'clearCartActionText',
  CLEARE_AWARE_MSG: 'clearCartAwareText',
  CLEAR_CART_COMPLETE_MSG: 'clearCartCompleteText',
  STORE_CLOSED: 'storeClosed',
  DELIVERY_NOT_AVAIALABLE: 'deliveryNotAvailable',
  PRICE_CHANGE_TEXT: 'cartItems',
  PRICE_CHANGE_MAIN: 'priceChangedCartMain',
  ITEM_UNAVAILABLE: 'itemUnavailable',
  SERVICE_CHANNEL: 'web',
  UNAVAILABLE: 'unavailable',
  UNAVAILABLE_TEXT: 'unavailableCartText',
  DINE_IN: 'dine-in',
  TAKEAWAY: 'takeaway',
  DINE_IN_TEXT: 'dineIn',
  GENERIC_ERROR: 'generic_error',
  CHECKOUT: 'checkout',
  DISPOISTIONUN_AVAL: 'dispositionUnavalMsg',
  UNAVAILABLE_ERROR: 'sorryUnaval',
  CART: 'cart',
  SCHEDULE_ORDER_DATA: 'scheduleOrderData',
  CHANGE_ORDER_SETTING: 'changeOrderSettings',
};
