/**
 * Constants used in CouponsModal.js
 */
export const MODAL_HEADER_TEXT = 'offerDeals';
export const MODAL_CONTENT_HEADER = 'haveADiscountCode';
export const MODAL_CONTENT_DESC = 'oneCouponPerOrder';
export const COUPON_INPUT_LABEL = 'discountCode';
export const COUPON_APPLY_BUTTON = 'apply';
export const MODAL_MID_HEADER = 'cartOffersForYou';
export const USER_SIGN_IN_TEXT = 'exclusiveOffers';
export const SUCCESS_COUPON_MSG = 'couponSuccess';
export const APPLIED = 'Applied';
export const APPLIED_COUPON = 'APPLIED';
export const INVALIDATED = 'Invalidated';
export const EXPIRED_COUPON = 'EXPIRED';
export const INCORRECT_COUPON = 'INCORRECT';
export const INVALID_COUPON = 'INVALID';
export const SET_COUPON_ERROR_MESSAGE = 'SET_COUPON_ERROR_MESSAGE';
export const SET_COUPON_EXPIRY_MESSAGE = 'SET_COUPON_EXPIRY_MESSAGE';
export const COUPON_STATIC_MSG = 'Your cart is not eligible for the coupon you applied and it has been removed. ';
export const COUPON_EXPIRE_MSG = 'expiredMsg';

/**
 * Constants used in CouponCardDesktop.js and CouponCardMobile.jss
 */
export const DEFAULT_IMAGE_PATH = '/images/items/no-image.jpg';
export const REDEEM = 'redeem';
export const TERMS_AND_CONDITIONS = 'termsConditions';
export const TERMS_AND_CONDITIONS_TITLE = 'termsAndCondition';
export const TERMS_AND_CONDITIONS_URL = 'terms-and-conditions';
export const TERMS_HEADLINE = 'Main Headline';
export const OFFER_APPLIED = 'Offer Applied';
