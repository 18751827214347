import React from 'react';
import ReactDOM from 'react-dom';
import KFCApp from 'app/KFCApp';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-datepicker/dist/react-datepicker.css';

if (window.supportsES6 === true) {
  ReactDOM.hydrate(<KFCApp />, document.getElementById('root'));
}

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
