const SEARCHSTORE_MODULE_DATA = {
  contentData: {
    searchStoreHeaderText: 'selectKFC',
    deliveryHeaderText: 'deliveryAddress',
    searchStoreModalContent: {
      formContent: {
        memberText: 'alreadyMember',
        singInLink: {
          linkUrl: null,
          linkText: 'signIn',
          targetType: '_blank',
        },
        currentLocationText: 'storeUseMyLocation',
        addressBoxPlaceHolderForSearchStore: 'addressBoxPlaceHolderForSearchStore',
        addressBoxPlaceHolderForDelivery: 'enterYourDeliveryAddress',
        mapText: 'map',
        numberOfStoreText: 'nearYou',
      },
      locationsContent: {
        storeData: [
          {
            map: {
              mapText: 'Map',
              mapUrl: '',
            },
            facilities: {
              washroom: true,
              wheelchair: true,
              wifi: true,
            },
            buttonData: {
              scheduleOrderButtonData: {
                buttonText: 'Schedule Order',
                display: true,
              },
              orderNowButtonData: { buttonText: 'Order Now', display: true },
            },
          },
        ],
      },
    },
  },
};

export default SEARCHSTORE_MODULE_DATA;
