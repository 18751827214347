import { PhonePe } from 'phonepesdk-web';
import constants from 'components/checkout/Constants/checkoutConstants';

export const checkForLocationConsent = arr => {
  const locationObj = arr.find(item => String(item.permission) === PhonePe.Constants.Permission.LOCATION);
  return Boolean(locationObj && locationObj?.permissionGranted);
};

export const checkLocationPermission = async () => {
  const sdk = await PhonePe.PhonePe.build(PhonePe.Constants.Species.web);
  const result = await sdk.seekPermission([PhonePe.Constants.Permission.LOCATION]);
  return Boolean(result && checkForLocationConsent(result));
};

export const payUsingPhonePeSDK = (reservationId, fallbackURL, cb) => {
  console.log('reservationId::', reservationId);
  console.log('fallbackURL::', fallbackURL);
  PhonePe.PhonePe.build(PhonePe.Constants.Species.web).then(sdk => {
    sdk
      .proceedToPay(reservationId, fallbackURL)
      .then(response => {
        console.log('Payment was successful = ' + response);
        cb(constants.PHONEPE_SWITCH_CONSTANTS.SUCCESS);
      })
      .catch(err => {
        console.log('Payment failed' + err);
        cb(constants.PHONEPE_SWITCH_CONSTANTS.FAILURE);
      });
  });
};
