export const MENU_PDP_PAGE_CONSTANTS = {
  SLIDES_TO_SHOW: 4,
  SLIDES_TO_SCROLL: 1,
  // FADE_CARD_INDEX: window.innerWidth < 1330 ? 3 : 4,
  FADE_CARD_INDEX: 3,
  CUSTM_EXPAND_TYPE: 'customExpandType',
  EXPAND_ACCORDIAN: 'expandAccordianById',
  MODGROUP_IDS_UPDATE: 'modGroupIdsUpdate',
  RESET_MODGROUP_IDS_UPDATE: 'resetModGroupIdsUpdate',
  UPDATE_DEFAULT_MODGROUP: 'UPDATE_DEFAULT_MODGROUP',
  IS_OUT_OF_STOCK: 'OutOfStock',
  IS_IN_STOCK: 'InStock',
  SET_PDP_DEEPLINK_MODE: 'SET_PDP_DEEPLINK_MODE',
  SHORT: 'short',
  LONG: 'long',
  READ_MORE: 'readMore',
  READ_LESS: 'readLess',
  MAX_LENGTH_READ: 35,
  YYYYMMDD: 'YYYY-MM-DD',
  YYYYMMDD_hhmm_A: 'YYYY-MM-DD hh:mm A',
  INDIA: 'INDIA',
  UPSELL: 'upsell',
  CROSSELL: 'crossell',
  MAKE_IT_COMBO_HEADER: 'makeItACombo',
  MAKE_IT_COMBO_SUBHEADER: 'comboSubHeader',
  PDP: 'PDP',
};
