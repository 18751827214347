import Bowser from 'bowser';

const DEFAULT_MIN_SUPPORTED_BROWSERS = {
  chrome: '>=51',
  firefox: '>=52',
  edge: '>=14',
  safari: '>=10',
};

const { KEY_MIN_SUPPORTED_BROWSERS, KEY_IS_BROWSER_SUPPORTED } = {
  KEY_MIN_SUPPORTED_BROWSERS: 'min.supported.browser',
  KEY_IS_BROWSER_SUPPORTED: 'is.browser.supported',
};

class BrowserManager {
  constructor() {
    this._data = new Map();
    this.browser = Bowser.getParser(window?.navigator?.userAgent);
  }

  get minSupportedBrowsers() {
    return this._data.get(KEY_MIN_SUPPORTED_BROWSERS);
  }

  set minSupportedBrowsers(value) {
    this._data.set(KEY_MIN_SUPPORTED_BROWSERS, value);
  }

  get isBrowserSupported() {
    return this._data.get(KEY_IS_BROWSER_SUPPORTED) ?? false;
  }

  set isBrowserSupported(value) {
    this._data.set(KEY_IS_BROWSER_SUPPORTED, value ?? false);
  }

  bind(minSupportedBrowsers) {
    if (!minSupportedBrowsers) {
      minSupportedBrowsers = JSON.stringify(DEFAULT_MIN_SUPPORTED_BROWSERS);
    }
    const minBrowsersSupported = JSON.parse(minSupportedBrowsers);

    for (const [key, value] of Object.entries(DEFAULT_MIN_SUPPORTED_BROWSERS)) {
      if (!minBrowsersSupported[`${key}`]) {
        minBrowsersSupported[`${key}`] = value;
      }
    }

    this.minSupportedBrowsers = minBrowsersSupported;
    this.isBrowserSupported = this.browser.satisfies(this.minSupportedBrowsers) ?? true;
  }
}

const instance = new BrowserManager();
Object.freeze(instance);

export default instance;
