import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { isSupportedTenantCode } from 'common/utilities/utils';
import { login } from 'common/utilities/LoginUtils';
import { RouteComponent } from './RouterInfo';
import { ROUTE_URL } from '../common/constants/SharedConstants';
import AuthManager from '@kfc-global/react-shared/auth/AuthManager';

const { getInstance: auth } = AuthManager;

const PrivateRoutes = ({ path, name, exact = false, additionalProps }) => {
  if (auth().isValidAccessToken && !auth().isMember) {
    if (!isSupportedTenantCode()) {
      login();
    } else {
      return (
        <Redirect
          to={{
            pathname: ROUTE_URL.HOME_PAGE,
          }}
        />
      );
    }
  }

  return (
    <Route
      exact={exact}
      path={path}
      render={props => <RouteComponent name={name} {...{ ...props, ...additionalProps }} />}
    />
  );
};

export default PrivateRoutes;
