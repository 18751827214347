export const HEADER_TITLE = 'offerDeal';
export const MODAL_HEADER_TEXT_DESKTOP = 'offerDeals';
export const MODAL_HEADER_TEXT_MOBILE = 'offer';
export const MODAL_HEADER_TEXT = 'offerDeals';
export const NON_LOCALIZED_HEADER_TEXT = 'nationalOffers';
export const LOCALIZED_HEADER_TEXT = 'offersForYou';
export const LOGIN_TEXT = 'SignInToSeeOffers';
export const OFFER_APPLIED = 'offerApplied';
export const REDEEM = 'redeem';
export const VIEW_DETAILS = 'viewdetails';
export const FIND_A_KFC = 'findKfc';
export const LOGIN = 'login';
export const VALID_TO = 'validTo';
export const DEFAULT_PAGE_SIZE = 9;
export const DEFAULT_START_PAGE = 1;
export const VIEWED = 'viewed';
export const OF = 'of';
export const LOAD_MORE = 'loadMore';
export const APPLIED = 'Applied';
export const SUCCESS_TOAST_MESSAGE = 'fingerLickin';
export const DEFAULT_IMAGE_PATH = '/images/items/no-image.jpg';

export const SHOW_COPY_COUPON_ACTION = 'SHOW_COPY_COUPON_ACTION';
export const HIDE_COPY_COUPON_ACTION = 'HIDE_COPY_COUPON_ACTION';
export const KFC_LOCAL_OFFERS = 'kfcLocalOffers';
export const SIGN_IN_EXCLUSIVE_OFFERS = 'kfcSignInExclusiveOffers';
