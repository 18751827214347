import React, { useEffect } from 'react';
import worker from 'workerize-loader!../../../worker';
import { MESSAGE, ACTIVITY_EVENTS } from 'common/constants/SharedConstants';
import { SYSTEM_IDLE_TIMEOUT } from '@kfc-global/react-shared/config/config.constants';
import { config } from '@kfc-global/react-shared/config/config.utils';

// Create an instance of worker
const workerInstance = worker();

const WorkerComponent = props => {
  const { setIsReturningUser, cartCount, userParams } = props;

  useEffect(() => {
    const workerEvent = message => {
      if (message.data.isUserInactive && userParams.localized && cartCount === 0) {
        setIsReturningUser(true);
      }
    };
    const CONFIG_SYSTEM_IDLE_TIMEOUT = config(SYSTEM_IDLE_TIMEOUT);
    workerInstance.addEventListener(MESSAGE, workerEvent);
    workerInstance.checkUserActivity(CONFIG_SYSTEM_IDLE_TIMEOUT);

    ACTIVITY_EVENTS.forEach(eventName => {
      document.addEventListener(eventName, () => workerInstance.checkUserActivity(CONFIG_SYSTEM_IDLE_TIMEOUT), true);
    });

    return () => {
      workerInstance.removeEventListener(MESSAGE, workerEvent);
    };
  }, [userParams.localized, cartCount]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default WorkerComponent;
