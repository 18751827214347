import React from 'react';
import { NotFoundError } from 'organisms/NotFound/Presentation/NotFoundError';
import { Helmet } from 'react-helmet';

export const NotFound = props => {
  return (
    <>
      <Helmet>
        <meta name='prerender-status-code' content='404' />
      </Helmet>
      <NotFoundError data-testid='not-found-404-testid' history={props.history} />
    </>
  );
};
