import React, { useEffect, useState } from 'react';

/**
 * Image Component Items JUST a View
 * @param {*} props - Object
 */
const ImageCompItems = props => {
  const { srcFile, isContentfulImage = false, defaultPath = '', alt: altText = '', containerRef, ...rest } = props;
  const [src, setSrc] = useState(null);
  const [shouldRetry, setShouldRetry] = useState(true);

  const errorHandler = event => {
    event.target.onerror = null;
    if (shouldRetry) {
      event.target.src = defaultPath;
    }
    setShouldRetry(false);
  };

  useEffect(() => {
    if (isContentfulImage) {
      console.log(`ImageCompItems: ${containerRef}`);
      if (containerRef?.clientWidth > 0) {
        setSrc(`${srcFile}?w=${containerRef.clientWidth}&fit=fill&fm=webp`);
      }
    } else {
      setSrc(srcFile);
    }
  }, [containerRef, isContentfulImage, srcFile]);

  // When SRC File is empty then No Need to render the IMage tag Itself
  if (src) {
    return <img onError={errorHandler} loading='lazy' src={src} alt={altText || ''} {...rest} />;
  }
  return null;
};

// default export
export default ImageCompItems;
