import React from 'react';
import dynamicImportConfig from 'common/config/dynamicImportConfig';

/*********************************************************************
 * This Lazy Loader Wrapper component
 * useful to avoid load or import multiple time of same code / files
 * get path from dynamic import config code will be reusable
 * avoid load multiple fallback for lazy load
 *
 * props?.importPath should be "page_name.file_name" i.e APP_HEADER_ORDER_TRACKER_COMP
 *********************************************************************/
const LazyLoaderWrapper = props => {
  const Component = React.useMemo(() => {
    const imports = dynamicImportConfig?.[process?.env?.REACT_APP_TENANT_CODE?.toUpperCase()]?.[props?.importPath];
    return imports && React.lazy(() => imports);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.importPath]);

  return props?.importPath && Component ? (
    <React.Suspense fallback={<div></div>}>
      <Component {...props} data-testid={`test_${props?.testId}`}></Component>
    </React.Suspense>
  ) : null;
};

export default LazyLoaderWrapper;
