import React from 'react';

const PullingContent = () => {
  return (
    <div>
      <p>&#8615;&nbsp;&nbsp;pull to refresh&nbsp;&nbsp;&#8615;</p>
    </div>
  );
};

export default PullingContent;
